
@import url('https://fonts.googleapis.com/css?family=Fira+Sans:300,400,600|Noto+Sans+JP:400,700,900&display=swap');
@import url('https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css');
@import url('https://unpkg.com/react-vis/dist/style.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.css');
@import url('https://api.tiles.mapbox.com/mapbox-gl-js/v1.3.0/mapbox-gl.css');

/* Overrriding Bootstrap */







/* Style taken from https://codesandbox.io/s/nmy6x9wrj */




/* GLOBAL STYLES */

html,
body {
  margin-left: 0;
  font-family: 'Fira Sans', sans-serif;
  background-color: #f8f8f8;
}

::-webkit-scrollbar {
  width: 0px;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}


.jumbotron-fluid {
  background-color: #012f38 !important;
  padding-top: 5% !important;
  padding-bottom: 7% !important;
  margin-bottom: 0 !important;
}

.fullscreen-dark {
  background-color: #012f38 !important;
}

/* UTILITIES */

.no-webkit {
  -webkit-appearance: none;
}

.bottom-of-column
{
    float: none;
    display: table-cell;
    vertical-align: bottom;
}

._100vw {
  min-width: 100vw !important;
  margin: 0;
}

._85vw {
  min-width: 85vw !important;
}



._65vw {
  min-width: 65vw !important;
}

._45vw {
  min-width: 45vw !important;
}

._minh-50vh {
  max-height: 50vh !important;
}

.no-x-overflow {
  overflow-x: hidden !important;
}
.visible-x-overflow {
  overflow-x: visible !important;
}
.visible-y-overflow {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.small {
  font-size: 0.7rem;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}
.noHover{ /* For when you don't want hover effects... */
  pointer-events: none;
} 

.pointer {
  cursor: pointer;
}

.square-corners {
  border-radius: 0;
}

.blue-grey-border {
  border-color: #103c44!important;
}

.full-height {
  min-height: 100vh;
}

.full-width {
  min-width: 100vw;
}

.no-outline {
  outline: none !important;
}

.no-shadow {
  box-shadow: none !important;
}

.cast-shadow-up {
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.45);
}

.cast-shadow {
  box-shadow: 10px 20px 80px rgba(0, 0, 0, 0.15) !important;
}

.no-padding {
  padding: 0 !important;
}

.vertical-center {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

.pinned-bottom-right{
  position: 'absolute';
  bottom: '0px';
  right: '0px';
  padding: '2%';
  background: #012f38;
}

.no-border{
  border: none !important;
}

.rounded-corners tr:first-child td:first-child { border-top-left-radius: 10px; }
.rounded-corners tr:first-child td:last-child { border-top-right-radius: 10px; }
.rounded-corners tr:last-child td:first-child { border-bottom-left-radius: 10px; }
.rounded-corners tr:last-child td:last-child { border-bottom-right-radius: 10px; }

.no-outline {
  outline: none !important;
}

.magnify {
  transform:scale(1.05);
  transform-origin:center;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);

}

.magnify-hover:hover {
  transform:scale(1.05);
  transform-origin:center;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.no-border-radius {
  border-radius: 0px;
}

.float-left{
  float: 'left';
}

.float-right{
  float: 'right' !important;
}

.thumbnail-circle-border {
  display: inline-flex;
  border-radius: 50px;
  border: 2px solid #33ea6f;
  margin-bottom: 4px;
  margin-right: 4px;
  padding: 2px;
  box-sizing: border-box
}

.no-opacity{ 
  opacity: '1.0';
}

.z-index-2 {
  z-index: 2;
}

.negative-mr-5{
  margin-right: -5%;
}

/* TEXT STYLES */
h1, h2, h3, h4, h5, p {
  color: #00232d;
}

h1 {
  font-size: 5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.3rem;
}

h4 {
  font-size: 1.1rem;
  font-weight: 400;
}

p{
  font-weight: 400;
}

b {
  font-weight: 800;
}

.caption {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 1.0rem;
  text-transform: none;
  font-weight: 400;
}

.caption-small {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 0.85rem;
  text-transform: none;
  font-weight: 300;
}

.caption-smaller {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 0.7rem;
  text-transform: none;
  font-weight: 300;
}

.caption-smallest {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 0.6rem;
  text-transform: none;
  font-weight: 300;
}

.uppercase {
  text-transform: uppercase;
}

.white-text {
  color: #ffffff !important;
}

.green-text {
  color: #3bd62b !important;
}

.error-text {
  color: #fd6567;
}

.text-ellipsis {
  text-overflow: ellipsis !important;
  overflow: hidden;
  white-space: nowrap;
}

.primary-link {
  color: #00232d;
  background: none !important;
  text-decoration: none;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.primary-link:hover {
  color: #3bd62b !important;
  background: none !important;
  text-decoration: none;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.white-link {
  color: #ffffff !important;
  background: none !important;
}
.white-link:hover {
  color: #3bd62b !important;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  text-decoration: none;
}

.green-link {
  color: #3bd62b !important;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.green-link:hover {
  color: #d6ffd2 !important;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  text-decoration: none;
}

.pale-green-link {
  color: #d6ffd2 !important;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.pale-green-link:hover {
  color: #00232d !important;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  text-decoration: none;
}

.grey-link {
  color: #c6cdcf !important;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.grey-link:hover {
  color: #00232d !important;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  text-decoration: none;
}


.dark-text, .dark-color {
  /*color: #739399 !important;*/
  color: #00232d !important;

}

.grey-text, .grey-color {
  /*color: #739399 !important;*/
  color: #c6cdcf !important;

}

.mid-grey-text, .mid-grey-color {
  color: #739399 !important;
}

.blue-grey-text, .blue-grey-color {
  /*color: #739399 !important;*/
  color: #739399 !important;

}

.noto-sans {
  font-family: 'Noto Sans JP', sans-serif;
}

.fira-sans {
  font-family: 'Fira Sans' sans-serif;
}

.bold-text {
  font-weight:900;
}


/* SVG STYLING */
.svg-primary {
  stroke: #33ea6f !important;
  fill: #33ea6f !important;
  color: #3bd62b !important;
}


/* MAPBOX STYLING */
.mapboxgl-ctrl-geocoder {
  width: 100% !important;
  background: transparent !important;
  box-shadow: none !important;
}

.mapboxgl-ctrl input {
  width: 100%;
  background: transparent;
  box-shadow: none;
  color: #fff;
  border-bottom: 3px solid #3bd62b;
  border-radius: 0 !important;
}
.mapboxgl-ctrl input::placeholder {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #739399 !important;
  -webkit-appearance: none;
}
.mapboxgl-ctrl input:hover {
  color: #739399 !important;
  background: none;
  border-bottom-color: #33ea6f;
  -webkit-appearance: none;
  transition:0.3s;
}
.mapboxgl-ctrl input:focus {
  color: #739399 !important;
  background: none;
  border-bottom-color: #d6ffd2;
  -webkit-appearance: none;
  transition:0.3s;
}
.mapboxgl-ctrl input:active {
  color: #739399 !important;
  background: none;
  border-bottom-color: #d6ffd2;
  -webkit-appearance: none;
  transition:0.3s;
}
.mapboxgl-ctrl svg {
  stroke: #3bd62b !important;
  fill: #3bd62b !important;
  color: #3bd62b !important;
}

.mapboxgl-ctrl button {
  background:none;
}

.mapboxgl-ctrl-geocoder .suggestions {
  background: #00232d !important;
  box-shadow: 10px 20px 80px rgba(0, 0, 0, 0.15) !important;

  
}
.mapboxgl-ctrl-geocoder .suggestions > li > a {
  color: #ffffff !important;
  cursor: pointer;
}

.mapboxgl-ctrl-geocoder .suggestions > .active > a, .mapboxgl-ctrl-geocoder .suggestions > li > a:hover {
  color: #ffffff !important;
  background-color: #012f38 !important;
  cursor: pointer;
}

/* BUTTON CUSTOMISATION */


.btn-primary, .btn {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 0.95rem;
  text-transform: uppercase;
  font-weight: 600;
  -webkit-appearance: none;
  color: #ffffff;
  outline: none;
  /* box-shadow: none !important; */
  background-color: #012f38;
  border: 3px solid #3bd62b;
  border-radius: 15px;
  outline: none;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);;
}

.btn-primary.focus, .btn-primary:focus {
  box-shadow: none !important;
}

.btn-secondary {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 0.95rem;
  text-transform: uppercase;
  font-weight: 600;
  -webkit-appearance: none;
  color: #739399;
  outline: none;
  /* box-shadow: none !important; */
  background-color: #012f38;
  border: 3px solid transparent;
  border-radius: 15px;
  outline: none;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);;
}

.btn-primary-small {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 0.7rem;
  text-transform: uppercase;
  font-weight: 600;
  -webkit-appearance: none;
  color: #ffffff;
  outline: none;
  /* box-shadow: none !important; */
  background-color: #012f38;
  border: 3px solid #3bd62b;
  border-radius: 15px;
  outline: none;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);;
}


.btn-primary-small:hover, .btn-primary:hover, .btn:hover {
  -webkit-appearance: none;
  color: #ffffff;
  outline: none;
  box-shadow: 10px 20px 80px rgba(0, 0, 0, 0.25) !important;
  background-color: #103c44;
  border: 3px solid #3bd62b;
  outline: none;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);;
}

.btn-primary-small:active, .btn-primary:active, .btn:active {
  -webkit-appearance: none;
  color: #ffffff;
  outline: none;
  box-shadow: 10px 20px 80px rgba(0, 0, 0, 0.25);
  background-color: #00232d;
  border: 3px solid #3bd62b;
  outline: none;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);;
}

.btn-primary-small:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled).active {   /* OVERRIDING BOOTSTRAP */
  -webkit-appearance: none;
  color: #ffffff;
  outline: none;
  box-shadow: 10px 20px 80px rgba(0, 0, 0, 0.25) !important;
  background-color: #00232d;
  border: 3px solid #3bd62b;
  outline: none;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);;
}



.btn-primary-small:disabled, .btn-primary:disabled{
  color: #fff;
  background-color: #012f38;
  border: 3px solid #103c44 !important;
  border-color: #012f38;
}


.primary-button {
  -webkit-appearance: none;
  outline: none;
  /* box-shadow: none !important; */
  background-color: #33ea6f !important;
  color: #00232d !important;
  border: none;
}

.primary-button:hover {
  -webkit-appearance: none;
  outline: none;
  box-shadow: none !important;
  background-color: #d6ffd2 !important;
}

.primary-button:active {
  -webkit-appearance: none;
  outline: none;
  box-shadow: none !important;
  background-color: #d6ffd2 !important;
}

.primary-button:focus {
  -webkit-appearance: none;
  outline: none;
  box-shadow: none !important;
  background-color: #d6ffd2 !important;
}

.text-button-small {
  background:none;
  font-weight: 700;
  font-size: 0.7rem;
  color: #00232d;
  border: 3px solid transparent;
  -webkit-appearance: none;
  outline: none;
  box-shadow: none !important;
}

.text-button-white-small {
  background:none;
  font-weight: 700;
  font-size: 0.7rem;
  text-overflow: ellipsis !important;
  color: #ffffff;
  border: 3px solid transparent;
  -webkit-appearance: none;
  outline: none;
  box-shadow: none !important;
}


.text-button {
  background:none;
  font-weight: 700;
  color: #00232d;
  border: 3px solid transparent;
  -webkit-appearance: none;
  outline: none;
  box-shadow: none !important;
}

.text-button-no-background {
  background:none !important;
  font-weight: 700;
  color: #00232d;
  border:none !important;
  -webkit-appearance: none;
  outline: none;
  box-shadow: none !important;
}



.text-button-white {
  background:none;
  font-weight: 700;
  color: #ffffff;
  border: 3px solid transparent;
  -webkit-appearance: none;
  outline: none;
  box-shadow: none !important;
}

.text-button-green {
  background:none;
  font-weight: 700;
  color: #3bd62b;
  border: 3px solid transparent;
  -webkit-appearance: none;
  outline: none;
  box-shadow: none !important;
}

.text-button-green:hover {
  color: #ffffff;
  background:none;
  border: 3px solid transparent;
}

.text-button-green:active {
  color: #ffffff;
  background:#00232d !important;
  border:none;
}

.text-button-no-background:hover,  .text-button-no-background:active {
  background:none;
  font-weight: 700;
  color: #3bd62b;
  border:none !important;
  -webkit-appearance: none;
  outline: none;
  box-shadow: none !important;
}

.text-button-small:hover, .text-button:hover, .text-button-white-small:hover, .text-button-white:hover {
  color: #3bd62b;
  background:#00232d !important;
  border:3px solid transparent !important;
  border:none;
}

.text-button-small:active, .text-button:active, .text-button-white-small:hover, .text-button-white:active {
  color: #3bd62b;
  background:#00232d !important;
  border:none;
}


.dark-highlight-button {
  -webkit-appearance: none;
  color: #739399;
  outline: none;
  box-shadow: none !important;
  background-color: transparent;
  border: 3px solid #3bd62b !important;
  outline: none;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);;
}

.dark-highlight-button .btn .active {
  -webkit-appearance: none;
  color: #ffffff;
  outline: none;
  box-shadow: none !important;
  background-color: transparent !important;
  border: 3px solid #3bd62b !important;
  outline: none;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);;
}



.dark-highlight-button:hover {
  background-color: #012f38;
  border: 3px solid #d6ffd2;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);;
}

.dark-highlight-button:active {
  border: 3px solid transparent !important;
}

.tab {
  -webkit-appearance: none;
  color: #739399;
  outline: none;
  box-shadow: none !important;
  background-color: transparent;
  border: 3px solid transparent !important;
  border-radius: 20px !important;
  outline: none;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.tab-small {
  -webkit-appearance: none;
  font-size: 0.7rem;
  color: #739399;
  outline: none;
  box-shadow: none !important;
  background-color: transparent;
  border: 3px solid transparent !important;
  border-radius: 20px !important;
  outline: none;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}


.tab .btn .active, .tab-small .btn .active {
  -webkit-appearance: none;
  outline: none;
  box-shadow: 10px 20px 80px rgba(0, 0, 0, 0.15) !important;
  background-color: transparent !important;
  border: 3px solid transparent !important;
  outline: none;
  border-radius: 50px !important;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.tab .btn-primary-small:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled).active {
  /* border: 3px solid transparent !important; */

  
}

.tab:active {
  border: 3px solid transparent !important;
  box-shadow: 10px 20px 80px rgba(0, 0, 0, 0.15) !important;
  
}

 .btn-primary-small:disabled .tab, .btn-primary:disabled .tab {
  background: transparent !important;
}

.tab:hover, .tab-small:hover {
  background-color: #012f38;
  border: 3px solid #d6ffd2 !important;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);;
}

.transparent-button {
  background: transparent;
  border: none;
}

.transparent-button:hover {
  background: #012f38;
  border: none;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);;
}


/* SPINNER CUSTOMISATION */
.spinner-primary {
  color: #3bd62b !important;
}

/* PROGRESS BAR CUSTOMISATION */
.progress {
  height: 1.8rem;
  background-color: #d6ffd2;
}

.progress .progress-bar {
  height: 2.5rem;
  background-color: #00232d;
}


/* ELEMENT STYLES (IMAGES, ETC) */
.thumbInner {
  display: flex;
  min-width: 0;
  overflow: hidden;
}

.thumb {
  display: 'inline-flex';
  border-radius: 50;
  border: 2px solid #33ea6f;
  margin-bottom: 8;
  margin-right: 8;
  padding: 4;
  box-sizing: border-box;
}

/* SIDEBAR CUSTOMISATION */
.custom-sidebar-class { 
  width: 256px !important;
  justify-content: center;
  box-shadow: 10px 20px 80px rgba(0, 0, 0, 0.15);
} 


/* TABLE STYLE */

.table-top, .rt-thead, .-header {
  background-color: #00232d;
  border-radius: 5px !important;
  font-family: 'Noto Sans JP' sans-serif;
  font-weight: bold;
  padding: 1%;
  text-transform: uppercase;
  font-size: 0.9rem;
  color: #C6CDCB !important;
  border: none;
  
}

tbody tr {
  /* margin-top: 10px !important;
  margin-bottom: 10px !important; */
}

.error-row {
  border-left: 10px solid #fd6567 !important;
}

.table-background {
  background-color: #f8f8f8;
  box-shadow: 10px 20px 80px rgba(0, 0, 0, 0.15);
  border-radius: 10px 10px 10px 10px !important;
}
.table-background-dark {
  background-color: #012f38;
  box-shadow: 10px 20px 80px rgba(0, 0, 0, 0.15);
  border-radius: 5px !important;
}
.table-dark-background tr:hover {
  background-color: #012f38;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 10px 20px 80px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}


/* React table customisation */

.ReactTable {
  background-color: #f8f8f8;
  /* box-shadow: 10px 20px 80px rgba(0, 0, 0, 0.15); */
  border-radius: 10px 10px 10px 10px !important;
}

.ReactTable, .ReactTable .rt-table,  .ReactTable .rt-tbody  {
  overflow: visible !important;
}

.ReactTable .rt-thead .rt-tr {
  margin-top:0;
  margin-bottom:0;
}

.ReactTable, .table {
  overflow: hidden;
  border: none !important;

}

.ReactTable, .rt-table, .table td, .table th, .table thead th {
  border-top: none;
  border-bottom: none;
  
}
.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: none !important;
}



.ReactTable .rt-thead, .rt-tr {
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  /* border-radius: 10px 10px 0px 0px !important; */
  border-radius: 10px !important;
  padding:0;
  overflow:visible !important;
}

.rt-tr {
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: 10px 20px 80px rgba(0, 0, 0, 0.07);
}

.ReactTable .rt-tr:hover {
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  /* border-radius: 10px 10px 0px 0px !important; */
  border-radius: 10px !important;
  padding:0;
}

.rt-th {
  padding-top:2% !important;
  padding-bottom:2% !important;
}

.rt-resizable-header-content:hover, .rt-resizable-header-content:active {
  cursor: pointer;
  color: #33ea6f !important;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.-sort-desc  {
  box-shadow: inset 0 -5px 0 0 #33ea6f !important;
}

.-sort-asc {
  box-shadow: inset 0 5px 0 0 #33ea6f !important;
}

.ReactTable .rt-tbody .rt-tr:hover {
  background-color: #f8f8f8;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 10px 20px 80px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  transform:scale(1.02);
  transform-origin:center;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}





.rt-tbody .rt-td {
  border: none !important;
  white-space: pre-line !important;
  padding-top: 2% !important;
  padding-bottom: 2% !important;
  
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;

  text-align: center;
}

.rt-tr {
  border: none !important;
}

.-pagination {
  background-color: #00232d;
  box-shadow: 10px 20px 80px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  text-transform: uppercase !important;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 0.8rem;
  color: #C6CDCB;
  /* border-radius: 0px 0px 10px 10px !important; */
  border-radius: 10px !important;
}

/*
.-pagination .-btn {
  background-color: #012f38 !important;
  box-shadow: 10px 20px 80px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  text-transform: uppercase !important;
  font-family: 'Noto Sans JP', sans-serif;
  border: 3px solid #3bd62b !important;
  font-size: 0.8rem;
  color: #3bd62b;
  border-radius: 10px 10px 10px 10px !important;
}*/

.-btn {
  background:#012f38 !important;
  font-weight: 700 !important;
  font-size: 0.8rem !important;
  color: #3bd62b !important;
  border:none !important;
  text-transform: uppercase !important;
  -webkit-appearance: none !important;
  
  outline: none !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  
}

.btn:active {
  border: 3px solid #3bd62b;
}



.-btn:disabled {
  background-color: #00232d !important;
  opacity: 1 !important;
  border-color: #012f38 !important;
  -webkit-appearance: none;
  color: #739399 !important;
  outline: none;
  box-shadow: none !important;
  outline: none;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);;
}

.-pageInfo span {
  color: #fff;
  margin: 2%;
  padding: 2%;
}

.-pageJump input, .-pageSizeOptions select {
  background: transparent!important;
  border-bottom: 3px solid #3bd62b !important;
  color: #c6cdcf;
  border-radius: 0 !important;

}

.-pageSizeOptions select {
  background:none !important;
  font-weight: 700 !important;
  font-size: 0.8rem !important;
  color: #c6cdcf !important;
  -webkit-appearance: none !important;
  border: 3px solid #3bd62b !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 10px !important;
}

.-pageSizeOptions select:hover {
  -webkit-appearance: none !important;
  border: 3px solid #d6ffd2 !important;

}

.-pageJump input:focus, input:hover {
  border-bottom: 3px solid #d6ffd2 !important;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.table-background td:hover {
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 5px;
}

.rt-head, .thead, th {
  border:none;
}

.dark-modal .react-grid-Container {
  outline: none;
  text-align: center;
  box-shadow: 10px 20px 80px rgba(0, 0, 0, 0.15);
  
}

.dark-modal .react-grid-Main{
  outline: none;
  background: none;
}

.dark-modal .react-grid-Grid {
  border: none;
  background: none;
}

.dark-modal .react-grid-Header{
  font-size: 0.8rem;
  background: none;
}

.dark-modal .react-grid-HeaderRow {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.dark-modal .react-grid-HeaderCell {
  background: #00232d;
  font-family: 'Noto Sans JP' sans-serif;
  text-transform: uppercase;
  color: #739399;
  border: none;
  
}

.dark-modal .widget-HeaderCell__value {
  padding-top:5%;
  padding-bottom:0;
  margin:0;
  vertical-align: middle;
}

.dark-modal .react-grid-Viewport {
  
}

.cellChanged {
  background-color: #d6ffd2 !important;
  box-shadow: 10px 20px 80px rgba(0, 0, 0, 0.25);
  z-index: 1;
  /* transform:scale(1.05); */
  transform-origin:center;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.dark-modal .react-grid-Canvas {
  background: none;
  
  
}

.dark-modal .react-grid-Row .react-grid-Row--even {

}

.react-grid-Row.rowChanged > .react-grid-Cell {
  background: #d6ffd2 !important;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.dark-modal .react-grid-Row .react-grid-Cell {
  font-family: 'Fira Sans' sans-serif;
  font-weight: 700;
  font-size: 0.8rem;
  color: #739399;
  /* background: #f8f8f8; */
}

.dark-modal .react-grid-Row:hover .react-grid-Cell{
  background: #f8f8f8;
}

.dark-modal .react-grid-Row .react-grid-Cell__value {
  
}

/* Potential fix to rdg modal issues */
.rdg-editor-container { 
  z-index: 10000 !important; 
}

.dark-modal .react-grid-Cell:active {
  background: #d6ffd2;
  z-index:3 !important;
}

.dark-modal .rdg-selected {
  border-color: #3bd62b;
  background-color: rgba(51, 234, 111, 0.2);
  z-index:3 !important;
}

.dark-modal .rdg-selected .drag-handle {
  background: #33ea6f;
  z-index:3 !important;
}
.dark-modal .react-grid-cell-dragged-over-down {
  background: #33ea6f !important;
  opacity: 0.2;
  z-index:3 !important;
}
.dark-modal .react-grid-cell-dragged-over-up {
  background: #33ea6f !important;
  opacity: 0.2;
  z-index:3 !important;
}


.dark-modal .react-grid-Row .react-grid-Row--odd {

}

.modal-content {
  border: none !important;
}

/* WEBKIT AUTOFILL STUFF */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus   {
  -webkit-transition: background-color 9999s ease-out;
  transition: background-color 9999s ease-out;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  -webkit-text-fill-color: #739399;
}

/* ELEMENT STYLES */

.inputField {
  border-top:0;
  border-left:0;
  border-right:0;
  padding-top: 2%;
  padding-bottom: 1%;
  padding-left: 2%;
  background-color: transparent !important;
  background-image: none !important;
  background: 0%;
  border-bottom:3px solid #3bd62b;
  color:#739399 !important;
  -webkit-appearance: none;
  -webkit-autofill: none;
  outline: none;
  box-shadow: none !important;
  transition:0.3s;
}
.inputField::placeholder {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #739399 !important;
  -webkit-appearance: none;
}
.inputField:hover {
  color: #739399 !important;
  background: none;
  border-bottom-color: #33ea6f;
  -webkit-appearance: none;
  transition:0.3s;
}
.inputField:focus {
  color: #739399 !important;
  background: none;
  border-bottom-color: #d6ffd2;
  -webkit-appearance: none;
  transition:0.3s;
}
.inputField:active {
  color: #739399 !important;
  background: none;
  border-bottom-color: #d6ffd2;
  -webkit-appearance: none;
  transition:0.3s;
}

.custom-select-dropdown {

  padding-top: 0;
  padding-bottom: 0;
  padding-left: 2%;
  background-color: transparent !important;
  border-radius: 0px;
  /* border:3px solid #3bd62b; */
  color:#ffffff;

  font-family: 'Noto Sans JP', sans-serif;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 600;

  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  -moz-appearance: none;
  -webkit-appearance: none;

  -webkit-autofill: none;
  outline: none;
  box-shadow: none !important;
  transition:0.3s;
}

.MuiFormControl-root.custom-select-dropdown {
  width: 100% !important;
  font-size: 0.75rem;
  color: #ffffff;
}

.MuiFormControl-root.custom-select-dropdown {
  width: 100% !important;
  font-size: 0.75rem;
  color: #ffffff;
}

.MuiFormLabel-root, .MuiInputLabel-root, .MuiFormLabel-root {
  width: 100% !important;
  font-size: 0.85rem !important;
  font-weight: 600 !important;
  color: #739399 !important;
  font-family: 'Noto Sans JP', sans-serif !important;
}

.custom-select-dropdown.MuiSelect-selectMenu {
  width: 100% !important;
}

.custom-select-dropdown > .MuiInputBase-root {
  font-size: 0.80rem;
  color: #c6cdcf;
  margin-bottom: 10%;
}

.custom-select-dropdown > .MuiInputBase-root:hover {
  font-size: 0.80rem;
  color: #3bd62b;
  margin-bottom: 10%;
}

.custom-select-dropdown > .MuiInput-underline:before {
  border-bottom: 2px solid #3bd62b !important;
}
.custom-select-dropdown > .MuiInput-underline:after {
  border-bottom: 2px solid #00232d !important;
}

.custom-select-dropdown > .MuiSelect-icon {
  color: #3bd62b !important;
}

.custom-select-dropdown select {
  display: none; /*hide original SELECT element: */
}

.react-datepicker {
  font-family: 'Fira Sans', sans-serif !important;
  background: none !important;
  border: 1px solid transparent !important;
  border-radius: 10px !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.react-datepicker__month-container {
  background: #fff !important;
  border-radius: 10px !important;
}

.react-datepicker__triangle {
  border-bottom-color: #00232d !important;
}

.react-datepicker__triangle::before {
  top: 0px !important;
  border-bottom-color: none !important;
}

.react-datepicker__header {
  background-color: #00232d !important;
  border-bottom: 1px solid #012f38 !important;
}

.react-datepicker__day-name, .react-datepicker__current-month {
  color: #fff !important;
}

.react-datepicker__day--outside-month {
  color: #739399 !important;
}

.react-datepicker__day--weekend {
  color: #739399 !important;
}

.react-datepicker__month {
  background-color: #fff;
}

.react-datepicker__input-container .inputField {  /* Overriding react datepicker */
  width: 100%;
  font-weight: 800;
}

.react-datepicker-wrapper {  /* Overriding react datepicker */
  /*width: 100%;*/
}

.react-datepicker__day--selected {
  background-color: #3bd62b !important;
}

/* Overriding react-confirm-alert */
.react-confirm-alert-overlay{
  background-color: rgb(0,35,45, 0.98) !important;
}
.react-confirm-alert-body {
  background-color: #012f38;
  min-width: 800px;
}

.modal-content {      /* Overriding Bootstrap */
  background-color: transparent;
}

.custom-control-input:checked~.custom-control-label::before { /* Overriding bootstrap */
  color: #fff;
  border-color: #3bd62b;
  background-color: #3bd62b;
}

.close {  /* Overriding Bootstrap */
  color: #3bd62b; 
  transition:0.3s;
  text-shadow:none;
  opacity:1;
  outline: none !important;
}

.close:hover {  /* Overriding Bootstrap */
  color: #d6ffd2; 
  transition:0.3s;
  outline: none !important;
}

.popover {
  border: none !important;
  background-color: #f8f8f8;
  box-shadow: 10px 20px 80px rgba(0, 0, 0, 0.15);
}

.popover .arrow {
  border: none !important;
  border-color: #f8f8f8;
}

.bs-popover-auto[x-placement^=top]>.arrow::before, .bs-popover-top>.arrow::before {
  border: none !important;
}

.bs-popover-auto[x-placement^=right]>.arrow::before, .bs-popover-right>.arrow::before {
  border: none !important;
}

.wide-popover {
  max-width: 400px !important;
}

/* Page Styles */

.header-cards {
  margin-top: 0;
  background: linear-gradient(0deg, #f8f8f8 50%, #012f38 50%);
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);;
}

.header-cards-content {
  margin-bottom: 5% !important;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);;
}


.homepage-recents-cards {
  background-color: #d6ffd2;
  border: 4px solid transparent;
  box-shadow: 10px 20px 80px rgba(0, 0, 0, 0.15);
  padding: 3%;
  border-radius: 5px !important;
  margin:10px;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);;
}



.general-info-cards {
  background-color: #d6ffd2;
  border: 4px solid transparent;
  padding: 3%;
  box-shadow: 10px 20px 80px rgba(0, 0, 0, 0.15);
  border-radius: 5px !important;
  margin:10px;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);;
}

.transparent-card {
  background-color: transparent;
  border: 4px solid transparent;
  padding: 3%;
  box-shadow: none;
  border-radius: 5px !important;
  margin:10px;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);;
}

.bright-green-cards {
  background-color: #33ea6f !important;
  border: 4px solid transparent;
  box-shadow: 10px 20px 80px rgba(0, 0, 0, 0.15);
  border-radius: 5px !important;
  margin:10px;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);;
}

.selected-card {
  border: 4px solid #33ea6f;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);;
}

.primary {
  background-color: #33ea6f !important;
}

.secondary {
  background-color: #d6ffd2 !important;
}

.error-background {
  background-color: #fd6567 !important;
}

.warning-background {
  background-color: #FECE00 !important;
}

.pale-background {
  background-color: #f8f8f8 !important;
}

.white-background {
  background-color: #ffffff !important;
}

.med-dark-background {
  background-color: #103c44 !important;
}

.dark-background {
  background-color: #012f38 !important;
}

.darkest-background {
  background-color: #00232d !important;
}

.darkest-background-transparent {
  background-color: rgb(0,35,45, 0.98) !important;
}

.darkest-background-semi-transparent {
  background-color: #012f38a4 !important;
}


.transparent-background {
  background-color: rgb(0,0,0, 0.00) !important;
}


.dark-highlight {
  background-color: #103c44;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);;
}

.dark-highlight:hover {
  background-color: #012f38;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);;
}

.dark-highlight:active {
 
}


.selection {
  border: 3px solid;
  border-color: #3bd62b;
}

.foreground-primary {
  color: #3bd62b !important;
}

.foreground-secondary {
  color: #d6ffd2 !important;
}

.foreground-error {
  color:  #fd6567 !important;
}

.foreground-warning {
  color: #FECE00 !important;
}

#page-wrap {
  text-align: left;
  
  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}


/* SCROLLBAR CUSTOMISATION */

.validation-error {
  border-bottom: 3px solid #fd6567;
}
.validation-error-text p {
  color: #fd6567;
  margin-bottom: 0px;
}

/* MAPBOX CUSTOMISATION */
.mapboxgl-popup-close-button {
  color: #00232d !important;
  transform:scale(2);
  transform-origin:center;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 2;
}

.mapboxgl-popup-close-button:hover {
  color: #3bd62b !important;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background: transparent !important;
  
}

.mapboxgl-popup-content {
  background: #d6ffd2;
  padding: 0;
  margin: 0 !important;
  border: none !important;
  font-family: 'Fira Sans', sans-serif;
  
}


/* REACT-VIS CUSTOMISATION */
.dark-graph .rv-xy-plot__grid-lines__line{
  stroke: #012f38;
}

.dark-graph .rv-xy-plot__axis__tick__text {
  fill: #f8f8f8;
}

.rv-xy-plot__axis__title text, .rv-xy-plot__axis__title text {
  z-index: 1;
  fill: #739399;
  padding: 5%;
}


.rv-crosshair__line {
  background: #33ea6f !important;
}

.rv-crosshair__inner, .rv-crosshair__inner__content {
  background: #012f38;
  box-shadow: 10px 20px 80px rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);

}




/* ---------------------------------- */

/*
  Styles from this codepen:
  https://codepen.io/official_naveen/pen/rgknI
*/
